const defaultState = {
    loggedIn: false,
    user: {},
    logginErrorMessage: undefined,
    loading: false,
    forgetPasswordMessage: undefined,
    passwordSet: false
}

const userReducer = (state = defaultState, action) => {
    switch( action.type ) {
        case "SET_USER":
            return {
                ...state,
                loggedIn: true,
                user: { ...action.payload },
                logginErrorMessage: undefined,
                loading: false
            } 
        
        case "LOG_OUT":
            localStorage.clear();
            return {
                ...state,
                loggedIn: false,
                user: {},
                logginErrorMessage: undefined,
                loading: false
            }
        case "ERROR_LOGIN":
            //console.log("error_login", action.payload);

            return {
                ...state,
                logginErrorMessage: action.payload,
                loggedIn: false,
                loading: false

            }
        case "USER_IS_LOADING": 
            return {
                ...state,
                loading: true
            }

        case "FORGET_PASSWORD":
            return {
                ...state,
                loading: false,
                forgetPasswordMessage: action.payload
            }

        case "PASSWORD_SET": 
            return {
                ...state,
                loading: false,
                passwordSet: true

            }

        default: return state
    }
}

export default userReducer;