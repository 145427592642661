import { useSelector } from "react-redux";
import { useState } from 'react';
import HeaderComponent from '../../../components/header';
import { Button, DatePicker} from 'antd';
import StaffSurveyPage from '../../survey/staff_survey';
import HealthCentersDropDownComponent from "../../../components/healthCentersDropDown";


const StaffSurveyAdminPage = () => {
    const [date, setDate] = useState();
    const [healthArea, setHealthArea] = useState(null);
    const [centerId, setCenterId] = useState(null); 
    const [submitted, setSubmitted] = useState(false);

    const { RangePicker } = DatePicker;
    const userReducer = useSelector(state => state.userReducer);

    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
        return (<div>Needs access permission {userReducer.user.role}</div>)
    }

    return (
        <>
            <HeaderComponent />
            <br /><br />
            <div>
                <HealthCentersDropDownComponent setHealthArea={setHealthArea} setCenterId={setCenterId}>
                    <RangePicker picker="month" onPanelChange={value => setDate(value)} />&nbsp;<Button onClick={() => setSubmitted(true)}>Submit</Button>
                </HealthCentersDropDownComponent>
            </div>
            <br /><br />
            {<StaffSurveyPage submitted={submitted} setSubmitted={setSubmitted} centerId={centerId} healthArea={healthArea} date={date} />}
        </>


    );
}

export default StaffSurveyAdminPage;