import React from 'react';
import * as Survey from 'survey-react';
import "survey-react/survey.css";
import $ from "jquery";
import * as widgets from "surveyjs-widgets";
import "select2/dist/css/select2.css";
import "select2/dist/js/select2.js";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/ui/widgets/datepicker.js";



window["$"] = window["jQuery"] = $;
widgets.select2(Survey, $);
widgets.select2tagbox(Survey, $);
widgets.jqueryuidatepicker(Survey, $);

const SurveyComponent = ({json, onComplete, showCompletedPage}) => {
    let model = new Survey.Model(json);

    model.onUploadFiles.add((_, options) => {
        const formData = new FormData();
        options.files.forEach((file) => {
            formData.append(file.name, file);
        });
    
        fetch("https://api.surveyjs.io/private/Surveys/uploadTempFiles", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                options.callback(
                    "success",
                    options.files.map((file) => {
                        return {
                            file: file,
                            content: "https://api.surveyjs.io/private/Surveys/getTempFile?name=" + data[file.name]
                        };
                    })
                );
            })
            .catch((error) => {
                console.error("Error: ", error);
                options.callback("error");
            });    
    });
    return (<Survey.Survey model={model} onComplete={onComplete} showCompletedPage={showCompletedPage} />);
}


export default SurveyComponent;