import React, { useReducer } from 'react';
import Card from '../../components/card';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HeaderComponent from '../../components/header';

const AdminIndex = () => {
    const userReducer = useSelector(state => state.userReducer);
    const history = useHistory();

    if (userReducer.user.role === 'admin') {
        return (
            <div className="container">
                <HeaderComponent />
                <br />
                <div className="row">
                    <Card title="Doctors DB" content="Doctors Database" button="Edit" onClick={() => history.push(`/admin/employees`)} />
                    {/* <Card title="Dr Submissions" content="Centers who submitted their stats" button="Edit" onClick={() => history.push(`/admin/review/employees`)} /> */}
                    <Card title="Statistics Submissions" content="Centers who submitted their stats" button="Edit" onClick={() => history.push(`/admin/review/stats`)} />
                    <Card title="History of changes" content="History that was made to DB" button="Edit" onClick={() => history.push(`/admin/history`)} />
                    <Card title="CME Events" content="Add CME Events here" button="Edit" onClick={() => history.push('/admin/events')} />
                    <Card title="PHC Reports" content="Get all primary care reports" button="Edit" onClick={() => history.push('/admin/reports')} />
                    <Card title="Word Formatter" content="Formatting Tool to convert excel into word file" button="Edit" onClick={() => history.push('/admin/word')} />
                    <Card title="Settings" content="Get or set settings" button="Edit" onClick={() => history.push('/admin/settings')} />
                    <Card title="Patient Survey Result" content="Get the patient satisfaction survey result" button="Edit" onClick={() => history.push('/admin/survey')} />
                    <Card title="Staff Survey Result" content="Get the Staff satisfaction survey result" button="Edit" onClick={() => history.push('/admin/staff_survey')} />
                    <Card title="Bedridden Survey Result" content="Get the Bedridden satisfaction survey result" button="Edit" onClick={() => history.push('/admin/bedridden_survey')} />                        
                    <Card title="ACLS Database" content="Get the doctors ACLS stats" button="Edit" onClick={() => history.push('/admin/acls')} />
                </div>
            </div>
            );
    }
    else if (userReducer.user.role === 'stats') {
        return (
            <div className="container">
                <HeaderComponent />
                <br />
                <div className="row">
                    <Card title="Statistics Submissions" content="Centers who submitted their stats" button="Edit" onClick={() => history.push(`/admin/review/stats`)} />
                </div>
            </div>
            );
    }
    else if (userReducer.user.role === 'cme') {
        return (
            <div className="container">
                <HeaderComponent />
                <br />
                <div className='row'>
                    <Card title="CME Events" content="Add CME Events here" button="Edit" onClick={() => history.push('/admin/events')} />   
                </div>
            </div>
        )
    }
    else {
        return <h3>You don't have permission to access this page.</h3>
    }
}

export default AdminIndex;