import { useSelector } from "react-redux";
import { useState } from 'react';
import HeaderComponent from '../../components/header';
import { Button, DatePicker} from 'antd';
import StaffSurveyPage from '../survey/staff_survey';
import DownloadFile from "../../components/downloadFile";

const StaffSurveyStaffPage = () => {
    const [date, setDate] = useState();
    const [submitted, setSubmitted] = useState(false);

    const { RangePicker } = DatePicker;
    const userReducer = useSelector(state => state.userReducer);

    if (userReducer.user.role !== 'user') {
        return (<div>Needs access permission {userReducer.user.role}</div>)
    }

    return (
        <>
            <HeaderComponent />
            <br /><br />
            <div>
                    <RangePicker picker="month" onPanelChange={value => setDate(value)} />&nbsp;<Button onClick={() => setSubmitted(true)}>Submit</Button>
            </div>
            <br />
            <br /><br />
            <DownloadFile />
            <br />
            {<StaffSurveyPage submitted={submitted} setSubmitted={setSubmitted} centerId={userReducer.user._id} healthArea={userReducer.user.healthArea} date={date} />}
        </>


    );
}

export default StaffSurveyStaffPage;