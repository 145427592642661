import axios from 'axios';
console.log('========== BASEURL ===========:', process.env.REACT_APP_BASEURL)
class Service {
  getRestClient() {
    if (!this.serviceInstance) {
      this.serviceInstance = axios.create({
        baseURL: process.env.REACT_APP_BASEURL,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
      });
    }
    return this.serviceInstance;
  }
}

export default (new Service());