import { useSelector } from "react-redux";
import { useState } from 'react';
import HeaderComponent from '../../../components/header';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import service from '../../../config/service';
import { Button, Spin, Card, DatePicker, Switch } from 'antd';
import moment from 'moment';


const SettingsPage = () => {
    const {data, isLoading, isError } = useQuery('settings', async () => await service.getRestClient().get('/settings/get'), {refetchOnWindowFocus: false});
    const [date, setDate] = useState();
    const updateMutation = useMutation(async reportDate => {
        return service.getRestClient().post('/settings/set', { reportDate });
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('settings');
            alert('success');
        },
        onError: (error) => {
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }
    });

    const updateSurveyMutation = useMutation(async isSurveyOpen => {
        //alert(isSurveyOpen);
        return service.getRestClient().post('/settings/set', {isSurveyOpen});
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('settings');
        },
        onError: (error) => {
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }
    }
    )

    const queryClient = useQueryClient();

    const userReducer = useSelector(state => state.userReducer);

    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
        return (<div>Needs access permission {userReducer.user.role}</div>)
    }

    if (isLoading)
        return <div><HeaderComponent /> Loading</div>

    if (isError)
        return <div><HeaderComponent /> Error Loading Page</div>

    if (!data)
        return <div><HeaderComponent /> No Data</div>

    console.log(data.data.isSurveyOpen);

    return (<div className="container" >
            <HeaderComponent />
            <br /><br />
            <Spin spinning={updateMutation.isLoading}>
                <div className="row">
                    <Card title="Report Date">
                        <p>Set Report Date: {data.data?.reportDate || 'N/A'} </p>
                        <DatePicker picker="month" onPanelChange={value => setDate(value)} defaultValue={moment(data.data?.reportDate)} />&nbsp;<Button onClick={() => updateMutation.mutate(date)}>Submit</Button>

                    </Card>
                </div>
                <div className="row">
                    <Card title="Is Survey Open">
                    <Switch checked={data.data.isSurveyOpen} loading={updateSurveyMutation.isLoading} onChange={state => updateSurveyMutation.mutate(state)} />
                    </Card>
                </div>
            </Spin>

    </div>);
}

export default SettingsPage;