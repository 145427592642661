import AdminEmployeesComponent from "../../components/admin-employees";
import { useParams } from "react-router";
import { Spin, Alert, Card } from "antd";
import {useState} from 'react';
import EmployeesReview from './employees-review';
import { useSelector } from 'react-redux';
import HeaderComponent from '../../components/header';

const EmployeeListPage = () => {
    const {userId, section} = useParams()
    const userReducer = useSelector(state => state.userReducer);

    const [submitted, setSubmitted] = useState(true);
    const [reviewSubmitted, setReviewSubmitted] = useState(true);
    const [values, setValues] = useState({
        healthArea: 'All',
        occupation: section,
        center_id: userId,
        fileNo: '',
        email: '',
        name: '',
        mobile: '',
        hod: ''
    })

    const [completedEntries, setCompletedEntries] = useState(false);

    if (!userReducer.loggedIn) 
         return  <div>No access</div>
    

    //return <div>{JSON.stringify(userReducer.loggedIn)}</div>
    return (<div style={{alignItems: 'center', }}>
        <Spin spinning={submitted || reviewSubmitted}>
            <HeaderComponent />
            <br />
            <Card title="Important Information" style={{ width: '100%' }}>
                <ul>
                    {section == 'doctor' && (<li>This list is for primary care doctors (GPs and Family Physicians), wroking in general clinic or specialized clinics like diabetes, NCD, wellbaby ...etc</li>)}
                    {section == 'doctor' && (<li>Doctors from other specialities like ENT, Opthalmology, Maternity, Preventive Health, Dental, ...etc are not included</li>)}
                    {section == 'doctor' && (<li>Trainees doctors are not included</li>)}
                    <li>Please click on each {section} highlited in <span style={{backgroundColor: 'red', color: 'white'}}>Red</span> to review his detail, then click <span style={{backgroundColor: 'green', color: 'white'}}>Complete Button</span></li>
                    <li>If you have {section} not in this list, please add them by clicking on the <span style={{backgroundColor: 'blue', color: 'white'}}>Add New {section} button</span></li>
                    <li>If the {section} has resigned or moved outside the center. Please click on the <span style={{color: 'blue'}}>delete</span> next to his name</li>
                </ul>
            </Card>
            <br />
            <AdminEmployeesComponent values={values} submitted={submitted} setSubmitted={setSubmitted} secret section={section} setCompletedEntries={setCompletedEntries} />
            <EmployeesReview center_id={values.center_id} occupation={values.occupation} submitted={reviewSubmitted} setSubmitted={setReviewSubmitted} completedEntries={completedEntries} />
   </Spin>
    </div>);
}

export default EmployeeListPage;