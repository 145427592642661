const defaultState = {
    data: [],
    employees: [],
    json: [],
    isAdding: false,
    errorMessage: undefined
}

const dataReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_DATA":
            console.log("Here are they:", action.payload);
            return {
                ...state,
                data: action.payload.data,
                employees: action.payload.employees,
                json: action.payload.json,
                isAdding: false,
                errorMessage: undefined
            }


        case "ADD_DATA":
            return {
                ...state,
                arr: [...action.payload.arr, ...state.arr],
                isAdding: false,
                errorMessage: undefined
            }
        
        case "DEL_DATA":
            return {
                ...state,
                arr: state.arr.filter( item => item._id !== action.payload._id ),
                isAdding: false,
                errorMessage: undefined
            }

        case "SET_ADDING_LOADING":
            return {
                ...state,
                isAdding: true,
                errorMessage: undefined
            }

        case "SET_ERROR_MESSAGE":
            console.log("Set_Error_Message");
            return {
                ...state,
                errorMessage: action.payload,
                isAdding: false
            }
        default: return state
    }
}

export default dataReducer;