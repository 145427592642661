import { useSelector } from "react-redux";
import { useState } from 'react';
import HeaderComponent from '../../../components/header';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import service from '../../../config/service';
import { Table, Button, Modal, Form, Input, InputNumber, Select, Space } from 'antd';

const EventsPage = () => {
    const {data, isLoading, isError } = useQuery('events', async () => await service.getRestClient().get('/events'), {refetchOnWindowFocus: false});

    const addMutation = useMutation(async event => {
        await service.getRestClient().post('/addEvent', event);
    }, {
        onSuccess: () => {
            setVisible(false);
            queryClient.invalidateQueries('events');
            console.log('success');
        },
        onError: () => {
            console.log('error');
            alert('error adding event');
        }
    });

    const deleteMutation = useMutation(async event => {
        await service.getRestClient().post('/deleteEvent', event);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('events');
            console.log('successfully deleted');
        },
        onError: () => {
            console.log('error');
            alert('error deleting event');
        }
    });

    const queryClient = useQueryClient();


    const userReducer = useSelector(state => state.userReducer);
    const [visible, setVisible] = useState(false);
    
    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats' && userReducer.user.role !== 'cme') {
        return (<div>Needs access permission {userReducer.user.role}</div>)
    }

    if (isLoading)
        return <div><HeaderComponent /> Loading</div>

    if (isError)
        return <div><HeaderComponent /> Error Loading Page</div>

    const user = userReducer.user.email.split('@')[0]
    const isAdmin = userReducer.user.role === 'admin';

    const newData = data.data.filter( event => isAdmin ? true : event.template?.includes(user));

    const columns = [
        {
            title: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Event Name',
            dataIndex: 'name',
            render: (text, record) => <a href={`/admin/events/${record._id}`}>{text}</a>
        },
        {
            title: 'Event Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Event ID',
            dataIndex: 'eventID'
        },
        {
            title: 'CME Points',
            dataIndex: 'cmePoints'
        },
        {
            title: 'RegNum',
            dataIndex: 'regNum'
        },
        {
            title: 'SurveryID',
            dataIndex: 'surveyURL'
        },
        {
            title: 'template',
            dataIndex: 'template'
        },
        {
            title: '',
            key: 'action',
            align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <Space size="middle">
                            <Button type="link" onClick={() => {
                                //alert('delete ' + record.name);
                                deleteMutation.mutate(record);
                            }}>
                                delete
                            </Button>
                        </Space>
                    </div>
                )
            }
        }
    ];


    const templates = [
        {temp: 'gp-template', name: 'gp-template'},
        {temp: 'gp-template2', name: 'gp-template2'},
        {temp: 'gp-template3', name: 'gp-template3'},
        {temp: 'gp-template4', name: 'gp-template4'},
        {temp: 'gp-template5', name: 'gp-template5'},
        {temp: 'ncd-template_sahar', name: 'ncd-template'},
        {temp: 'ncd-template-2_sahar', name: 'ncd-template-2'},
        {temp: 'ncd-template-3_sahar', name: 'ncd-template-3'},
        {temp: 'ncd-template-4_sahar', name: 'ncd-template-4'},
        {temp: 'ncd-template_sahar_nocme', name: 'ncd-template(nocme)'},
        {temp:'geriatric-template_mai', name: 'geriatric-template'},
        {temp:'geriatric-template_mai2', name: 'geriatric-template2'},
        {temp: 'ebcpc-template', name: 'ebcpc-template'},
        {temp: 'cdphc-template', name: 'cdphc-template'},
        {temp: 'mental-template', name: 'mental-template'},
        {temp: 'emergency-template', name: 'emergency-template'},
        {temp: 'emergency-template2', name: 'emergency-template(for speakers)'},
        {temp: 'ncd-template', name: 'ncd-template(dalal)'},
        {temp: 'ncd-template-2', name: 'ncd-template-2(dalal)'},
        {temp: 'ncd-template-3', name: 'ncd-template-3(dalal)'},
        {temp: 'ncd-template-4', name: 'ncd-template-4(dalal)'},
        {temp: 'geriatric-template', name: 'geriatric-template(naser)'},
        {temp: 'pdgp-template', name: 'pdgp-template'},
        {temp: 'capital-template', name: 'capital-template'},
        {temp: 'wellbaby-template', name: 'wellbaby-template'},
        {temp: 'wellbaby-template2', name: 'wellbaby-template2'},
        {temp: 'women-template', name: 'women-template'},
    ]

    return (
        <div>
            <HeaderComponent />
            <br />
            <Button type="primary" onClick={() => setVisible(true)}>Add Event</Button>
            <br />
            <Table columns={columns} dataSource={newData} pagination={{pageSize: 1000}} />

            <Modal
                title="Add a new event"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Form layout="vertical" onFinish={(values) => addMutation.mutate(values) }>
                    <Form.Item label="Event Name" name="name">
                        <Input placeholder="Event Name" />
                    </Form.Item>
                    <Form.Item label="Event Date" name="date">
                        <Input placeholder="Event Date" />
                    </Form.Item>
                    {/* <Form.Item label="Event ID" name="eventID">
                        <Input placeholder="Event ID" />
                    </Form.Item> */}
                    <Form.Item label="CME Point" name="cmePoints">
                        <InputNumber min={0} max={100} />
                    </Form.Item>
                    <Form.Item label="RegNum" name="regNum">
                        <Input placeholder="RegNum" />
                    </Form.Item>
                    {/* <Form.Item label="pretestURL" name="pretestURL">
                        <Input placeholder="pretestURL" />
                    </Form.Item> */}
                    <Form.Item label="SurveyID" name="surveyURL">
                        <Input placeholder="surveyID" />
                    </Form.Item>
                    <Form.Item label="template" name="template">
                        <Select placeholder="template" >
                            {templates.map((t) => {
                                if (isAdmin || t.temp.includes(user)) {
                                    return (
                                        <Select.Option value={t.temp}>{t.name}</Select.Option>
                                    )                                   
                                }
                            }
                            )}
                        </Select>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}

export default EventsPage;