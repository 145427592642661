import service from '../config/service';

// Action creators
const setUserAction = (payload) => ({ type: "SET_USER", payload })
const errorLoginAction = (payload) => ({ type: "ERROR_LOGIN", payload })
export const logUserOutAction = () => ({ type: "LOG_OUT" })
export const userIsLoadingAction = () => ({ type: "USER_IS_LOADING" })
export const forgetPasswordAction = (payload) => ({ type: "FORGET_PASSWORD", payload })
export const passwordSetAction = () => ({ type: "PASSWORD_SET" })

// methods
export const fetchUser = (userInfo) => dispatch => {
    dispatch(userIsLoadingAction());

    service.getRestClient().post('/users/login', userInfo).then( (res) =>{
        localStorage.setItem("token", res.data.token);
        dispatch(setUserAction(res.data.user));
    } ).catch(e => {
        //console.log("error occured fetchUser")
        dispatch(errorLoginAction("Error occured while logging. Check your credintials or connection"));
    });
}

export const autoLogin = () => dispatch => {
    service.getRestClient().get('/users/autoLogin').then( res => {
        localStorage.setItem("token", res.data.token)
        console.log(res.data.user);
        dispatch(setUserAction(res.data.user))
    }).catch(e => {
        console.log("error occured in autologin: ", e);
    })
}

export const logoutUser = () => dispatch => {
    service.getRestClient().get('/users/logout').then( res => {
        dispatch(logUserOutAction(res.data.user))
    }).catch(e => {
        console.log("error occured in logout", e);
    })
}

export const forgetPassword = (email) => dispatch => {
    dispatch(userIsLoadingAction());

    service.getRestClient().post('/users/forget', email).then(res => {
        dispatch(forgetPasswordAction("Please check your email for the instruction."))
    }).catch(e => {
        dispatch(forgetPasswordAction('Sorry, could not retrieve your password. Kindly contact the support team'));
    })
}

export const setForgetPassword = (values) => dispatch => {
    dispatch(userIsLoadingAction());

    service.getRestClient().post('/users/setForgetPassword', values).then(res => {
        dispatch(passwordSetAction())
    }).catch(e => {
        dispatch(forgetPasswordAction('Sorry, could not set your new password.'));
    })
}