import React from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setForgetPassword } from '../actions/userActions';
import { Redirect, useParams } from 'react-router-dom';

const SetForgetPassword = () => {
    const userReducer = useSelector(state => state.userReducer)
    const dispatch = useDispatch();
    const {token} = useParams();

    const onFinish = (values) => {
        //alert(JSON.stringify(values))
        if (userReducer.loggedIn) {
            alert("You're already logged in");
        }
        else if (!token) {
            alert("Token are not set " + token);
        }
        else {
            values['token'] = token;
            dispatch(setForgetPassword(values));
        }
    }

    const showFormOrRedirect = () => {
        if (userReducer.passwordSet === false) {
            return (
                <div className="col-md-4 offset-md-4">
                    <h6>Please enter your registered email, and a new password:</h6>
                    
                    <Form layout="vertical"  width="40%" name="login" initialValues={{ remember: true }} onFinish={onFinish}>
                        <Form.Item label="Registered Email" name="email" rules={[ {required: true, message: "please enter your email"}]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="password" name="password" rules={[ {required: true, message: "please enter your password"}]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item label="confirm password" name="password2" dependencies={['password']} rules={[ 
                            {required: true, message: "please enter your confirm password"},
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            })
                            ]}>

                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={userReducer.loading}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                    {
                        userReducer.forgetPasswordMessage ? <Alert message={userReducer.forgetPasswordMessage} type="info" /> : ''
                    }
                    
                </div>
            )
        }
        else {
            return (
                <Redirect to="/login/success" />
            )
        }
    }
    return showFormOrRedirect();
}

export default SetForgetPassword;