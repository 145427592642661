import { useQuery } from 'react-query';
import service from '../../config/service';
import { Table } from 'antd';
import moment from 'moment';
import HeaderComponent from '../../components/header';

const AdminHistoryPage = () => {
    const {data, isLoading, isError } = useQuery('history', async () => await service.getRestClient().post('/employees/history'), {refetchOnWindowFocus: false})

    if (isLoading)
        return <div>Loading</div>

    if (isError)
        return <div>Error Loading Page</div>

    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            render: (text, record, index) => index+1,
        },
        {
            title: 'Employee',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Methods',
            key: 'diff',
            dataIndex: ['diff'],
            render: (diff) => {
                let output = '';

                for (let i=0; i<diff.length; i++) {
                    output += JSON.stringify(diff[i].method)  + '\n';
                }

                return output;
            }
        },
        {
            title: 'updatedAt',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => moment(text).format('DD/MM/YYYY')
        },
        {
            title: 'History',
            key: 'diff',
            dataIndex: ['diff'],
            render: (diff) => {
                let output = '';

                for (let i=0; i<diff.length; i++) {
                    if (diff[i].method == 'update')
                        output += JSON.stringify(diff[i].diff)  + '\n';
                }

                return output;
            }
        }
    ];

    if (data)
        return  (
            <div>
                <HeaderComponent /><br />
                <Table dataSource={data.data} columns={columns} pagination={{ pageSize: 20 }}/>
            </div>
                );
}

export default AdminHistoryPage;