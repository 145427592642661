import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useState, useSelector } from 'react';
import HeaderComponent from '../../../components/header';
import service from '../../../config/service';
import { useParams } from 'react-router-dom';
import {Card, Table, Button, Modal, Form, Input, Upload, message, Spin} from 'antd';
import { UploadOutlined } from '@ant-design/icons';


const EventDetailPage = () => {
    const {eventId} = useParams();
    const {data, isLoading, isError } = useQuery('eventDetail', async () => await service.getRestClient().post('/eventDetail', {_id: eventId}), {refetchOnWindowFocus: false});
    const addMutation = useMutation(async part => {
        return service.getRestClient().post('/addParticipant', part);
    }, {
        onSuccess: () => {
            setVisible(false);
            queryClient.invalidateQueries('eventDetail');
            console.log('success');
        },
        onError: (error) => {
            setVisible(false);
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }
    });

    const evaluationMutation = useMutation(async arr => {
        return service.getRestClient().post('/sendEvaluation', arr);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('eventDetail');
            console.log('success');
        },
        onError: (error) => {
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }
    });

    const issueMutation = useMutation(async arr => {
        return service.getRestClient().post('/issueCertificate', arr);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('eventDetail');
            console.log('success');
        },
        onError: (error) => {
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }

    });

    const deleteMutation = useMutation(async arr => {
        return service.getRestClient().post('/deleteParticipants', arr);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('eventDetail');
            console.log('successfully deleted');
            setSelectedRows([]);
        },
        onError: (error) => {
            console.log('error');
            alert('error deleting participants');
        }
    });

    const sendMutation = useMutation(async arr => {
        return service.getRestClient().post('/events/sendCertificate', arr);
    }, {
        onSuccess: (info) => {
            queryClient.invalidateQueries('eventDetail');
            console.log('success');
        },
        onError: (error) => {
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }
    });

    const queryClient = useQueryClient();

    // const userReducer = useSelector(state => state.userReducer);
    const [visible, setVisible] = useState(false);
    const [uploadVisible, setUploadVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    let rows = [];

    
    // if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
    //     return (<div>Needs access permission {userReducer.user.role}</div>)
    // }


    if (isLoading)
        return <div><HeaderComponent /> Loading</div>

    if (isError)
        return <div><HeaderComponent /> Error Loading Page</div>

    if (!data) {
        return <div><HeaderComponent /> Not found</div>
    }

    rows = data.data.participants.map((part, index) => {
        return {
            key: index,
            ...part
        }
    });


    const columns = [
        {
            title: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email'
        },
        {
            title: 'CME Points',
            key: 'cmePoints',
            dataIndex: 'cmePoints'
        },
        {
            title: 'Evaluation Sent',
            key: 'evaluationSent',
            dataIndex: 'evaluationSent',
            render: (text, record) => text ? 'Yes' : 'No'
        },
        {
            title: 'Evaluation Done',
            key: 'evaluationDone',
            dataIndex: 'evaluationDone',
            render: (text, record) => text ? 'Yes' : 'No'
        },
        {
            title: 'Certificate',
            key: 'certificate',
            dataIndex: 'certificate',
            render: (text, record) => text ? <a href={process.env.REACT_APP_BASEURL + '/certificates/' + data.data.event.eventID + '/' + record._id + '.pdf'} target="_blank">download</a> : 'No'
        },
        {
            title: 'Survey Link',
            render: (text, record) => <a href={'https://form.jotform.com/' + data.data.event.surveyURL + '?email=' + record.email + '&yourName=' + record.name} target="_blank">link</a>
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
        }
    ]; 
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedRows(selectedRows);
        },
        // onSelect: (record, selected, selectedRows) => {
        //   console.log(record, selected, selectedRows);
        // },
        // onSelectAll: (selected, selectedRows, changeRows) => {
        //   console.log(selected, selectedRows, changeRows);
        // },
      };

    return (
        <div>
            <HeaderComponent /><br />
            <Card title={data.data.event.name}>
                <p>CME POINTS: {data.data.event.cmePoints}</p>
                <p>DATE: {data.data.event.date}</p>
                <p>Total Attendance: {data.data.participants.length}</p>
                <p>Survey ID : <a href={'https://form.jotform.com/' + data.data.event.surveyURL} target="_blank">{data.data.event.surveyURL}</a></p>
                {/* {data.data.event.excel && <p>Excel Link : <a href={settings.baseURL + '/uploads/' + data.data.event.excel} target="_blank">Excel</a></p>} */}
                <p>Excel Links: {data.data.event.excel?.map(e => <><a href={process.env.REACT_APP_BASEURL + '/uploads/' + e.name} target="_blank">[{e.name}]</a>&nbsp;</>)}</p>
            </Card><br />
            <div>
                <Button type="primary" onClick={() => setUploadVisible(true)}>Upload Excel</Button>&nbsp;
                <Button type="primary" onClick={() => setVisible(true)}>Add Participant</Button>
            </div><br />
            <div>
                <Button type="dashed" onClick={() => selectedRows.length && evaluationMutation.mutate(selectedRows)}>Send Evaluation</Button>&nbsp;
                <Button type="dashed" onClick={() => selectedRows.length && issueMutation.mutate(selectedRows)}>Issue certificate</Button>&nbsp;
                <Button type="dashed" onClick={() => selectedRows.length && sendMutation.mutate(selectedRows)}>Send Certificate</Button>&nbsp;
                <Button type="dashed" onClick={() => selectedRows.length && deleteMutation.mutate(selectedRows)}>Delete Participants</Button>&nbsp;
            </div><br />
            <Spin spinning={evaluationMutation.isLoading||addMutation.isLoading||deleteMutation.isLoading||issueMutation.isLoading||sendMutation.isLoading}><Table dataSource={rows} columns={columns} rowSelection={{type: 'checkbox', ...rowSelection} } pagination={{pageSize: 1000}} /></Spin>

            <Modal
                title="Add a new participant"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Form layout="vertical" onFinish={(values) => addMutation.mutate({...values, event_id: data.data.event._id}) }>
                    <Form.Item label="Participant Name" name="name" required="true">
                        <Input placeholder="Participant Name" />
                    </Form.Item>
                    <Form.Item label="email" name="email" required="true">
                        <Input placeholder="email" />
                    </Form.Item>
                    <Form.Item label="CME Point" name="cmePoints" required="true">
                        <Input placeholder={data.data.event.cmePoints} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal> 
            <Modal
                title="Upload Excel File"
                visible={uploadVisible}
                onCancel={() => setUploadVisible(false)}
                onOk={() => {
                    setUploadVisible(false);
                    queryClient.invalidateQueries('eventDetail');
                }}
            >
                <Upload 
                    name="file"
                    action={`${process.env.REACT_APP_BASEURL}/uploadExcel?id=${data.data.event._id}`}
                    headers={{
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }}
                    onChange={(info) => {
                        if (info.file.status === 'done') {
                            message.success(`${info.file.name} file uploaded successfully`);
                            queryClient.invalidateQueries('eventDetail');
                        } else if (info.file.status === 'error') {
                            message.error(`${info.file.name} file upload failed.`);
                        }
                    }
                    }


                >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Modal>  
        </div>
    )
}

export default EventDetailPage;