import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { logoutUser } from  '../actions/userActions'

const Header = () => {
    const userReducer = useSelector(state => state.userReducer);
    const dispatch = useDispatch();
    const history = useHistory();


    return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light static-top">
            <div className="container">
                <a className="navbar-brand" href="/"><img className="img-fluid" width="20%" src="http://ebcpc.net/phc_logo.jpg" alt="" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse text-center" id="navbarResponsive">
                    <ul className="navbar-nav mx-auto text-center">
                        {
                            userReducer.user.role === 'user' ?
                            <li className="nav-item">
                            <a className="nav-link" href="/staff">Home</a>
                            </li> : ''

                        }

                            {
                            userReducer.user.role === 'admin' || userReducer.user.role === 'stats' ?
                            <li className="nav-item"><a className="nav-link" href="/admin">{userReducer.user.role}</a></li> :
                            ''
                            }
    
                    </ul>
                    
                    {
                        userReducer.loggedIn === false ? 
                        <Link to="/login">Login</Link>:
                        <div><span>Welcome {userReducer.user.name}</span><Button type="text" onClick={() => dispatch(logoutUser())}>( Logout )</Button></div>
                    }
                    
                </div>
            </div>
        </nav>
        {userReducer.user.role === 'admin' && <div><button onClick={history.goBack}>Back</button></div>}
    </div>
    );
}

export default Header;
