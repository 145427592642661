import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.css';

import Staff from './pages/staff/staff';
import DynamicPage from './pages/staff/dynamic';
import Login from './components/login';
import Forget from './components/forget';
import SetForgetPassword from './components/setForgetPassword';
import AdminIndex from './pages/admin';
import AdminPage from './pages/admin/admin';
import AdminEmployeesPage from './pages/admin/admin-employees';
import AdminHistoryPage from './pages/admin/admin-history';
import AddEmployee from './pages/employees/update/add';
import ThankyouComponent from './pages/employees/update/thanks';
import EmployeeListPage from './pages/employees/employeesList';
import EventsPage from './pages/admin/events/events';
import EventDetailPage from './pages/admin/events/event_detail';
import ReportsPage from './pages/admin/reports/reports';
import ReportDetailPage from './pages/admin/reports/report_detail';
import SettingsPage from './pages/admin/settings/settings';
import WordPage from './pages/admin/word/word';
import SurveyAdminPage from './pages/admin/survey/survey';
import StaffSurveyAdminPage from './pages/admin/survey/staff_survey';
import BedriddenSurveyAdminPage from './pages/admin/survey/bedridden_survey';
import SurveyStaffPage from './pages/staff/survey';
import StaffSurveyStaffPage from './pages/staff/staff_survey';
import OtherEmployeesPage from './pages/staff/other_employees';
import AdminACLSPage from './pages/admin/admin-acls';


import { autoLogin } from './actions/userActions';
import SurveyPage from './pages/survey/survey';
import UpdateEmployeePage from './pages/employees/update/update';

class App extends React.Component {
  componentDidMount() {
    this.props.autoLogin();
  }

  // !this.props.userReducer.loggedIn ? <h1>Sign Up or Login!</h1> : <h1>Welcome, {this.props.userReducer.user.name}</h1>
  render() {
    return (
        <div className="wrapper">
          <BrowserRouter>
            <br /><br />
            <Switch>
              <Route path="/staff/dynamic/:section/:userId">
                  <DynamicPage />
              </Route>
              <Route path="/admin" exact>
                <AdminIndex />
              </Route>
              <Route path="/admin/review/stats" exact>
                <AdminPage />
              </Route>
              <Route path="/admin/employees" exact>
                <AdminEmployeesPage />
              </Route>
              <Route path="/admin/acls" exact>
                <AdminACLSPage />
              </Route>
              <Route path="/admin/history" exact>
                <AdminHistoryPage />
              </Route>
              <Route path="/admin/events/:eventId">
                <EventDetailPage />
              </Route>
              <Route path="/admin/events">
                <EventsPage />
              </Route>
              <Route path="/admin/reports/:reportId">
                <ReportDetailPage />
              </Route>
              <Route path="/admin/reports">
                <ReportsPage />
              </Route>
              <Route path="/admin/settings">
                <SettingsPage />
              </Route>
              <Route path="/admin/word">
                <WordPage />
              </Route>
              <Route path="/admin/survey">
                <SurveyAdminPage />
              </Route>
              <Route path="/admin/staff_survey">
                <StaffSurveyAdminPage />
              </Route>
              <Route path="/admin/bedridden_survey">
                <BedriddenSurveyAdminPage />
              </Route>
              <Route path="/staff" exact>
                <Staff />
              </Route>
              <Route path="/staff/survey">
                <SurveyStaffPage />
              </Route>
              <Route path="/staff/staff_survey">
                <StaffSurveyStaffPage />
              </Route>
              <Route path="/staff/otheremployees">
                <OtherEmployeesPage />
              </Route>
              <Route path="/employees/:section/:userId">
                <EmployeeListPage />
              </Route>
              <Route path="/update">
                <AddEmployee />
              </Route>
              <Route path="/updateForm/:fileNo/:civilId/:id?">
                <UpdateEmployeePage />
              </Route>
              <Route path="/updateSecretForm/:fileNo?/:civilId?/:id?">
                <UpdateEmployeePage secret="yes" />
              </Route>
              <Route path="/forget">
                <Forget />
              </Route>
              <Route path="/thanks">
                <ThankyouComponent />
              </Route>
              <Route path="/setForgetPassword/:token">
                <SetForgetPassword />
              </Route>
              <Route path='/login/:success?'>
                <Login />
              </Route>
              <Route path="/survey">
                <SurveyPage />
              </Route>
              <Route path='/'>
                <Login />
              </Route>           
            </Switch>
          </BrowserRouter>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    autoLogin: () => dispatch(autoLogin())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);