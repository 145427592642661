import { Form, Input, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const AddEmployee = () => {
    const history = useHistory();
    return (
        <Form layout="vertical"  width="40%" name="login" initialValues={{ remember: true }} onFinish={(values) => history.push(`/updateForm/${values.fileNo}/${values.civilId}`)}>
        <Form.Item label="File No" name="fileNo" rules={[ {required: true, message: "please enter your file number"}]}>
            <Input />
        </Form.Item>
        <Form.Item label="Confirm File No" name="fileNo2" dependencies={['fileNo']} rules={[ 
                            {required: true, message: "please confirm your file number"},
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('fileNo') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two file numbers that you entered do not match!'));
                                },
                            })
                            ]}>
            <Input />
        </Form.Item>

        <Form.Item label="Civil ID" name="civilId" rules={[ {required: true, message: "please enter your civil id"}, {len: 12}]}>
            <Input />
        </Form.Item>
        <Form.Item label="Confirm Civil ID" name="civilId2" dependencies={['civilId']} rules={[ 
                            {required: true, message: "please confirm your civil id number"},
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('civilId') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two civil ids that you entered do not match!'));
                                },
                            })
                            ]}>
            <Input />
        </Form.Item>

        <Form.Item>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item>
    </Form>
    )
}

export default AddEmployee;