import { React } from 'react';
import Card from '../../components/card';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import HeaderComponent from '../../components/header';

export default function OtherEmployeesPage() {
    const userReducer = useSelector(state => state.userReducer);
    const history = useHistory();

    if (!userReducer.loggedIn)
        return <Link to="/login">Login</Link>

    return (
    <div className="container">
        <HeaderComponent />
        <br />
        <div>
            <ul>
                <li>
                    <span style={{color: 'red', fontWeight: 'bold'}}>Information in this section should be updated at least once a year</span>
                </li>
            </ul>
        </div>
        <div className="row">
            <Card title="Dental" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />
            <Card title="Nurse" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />
            <Card title="Pharmacy" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />
            <Card title="Lab" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />
            <Card title="XRay" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />
            <Card title="Maternity" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />
            <Card title="Porters" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />

        </div>

    </div>
    );
}