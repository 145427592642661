import { useSelector } from "react-redux";
import { useState, useRef } from 'react';
import HeaderComponent from '../../../components/header';
import { Upload, Button, Row, Col, Space, Select, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import excel from 'exceljs';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';

import 'handsontable/dist/handsontable.full.css';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { _getEditorInstance } from "handsontable/editors";

const areas = ['العاصمة', 'حولي', 'الفروانية', 'الاحمدي', 'الجهراء', 'مبارك الكبير'];

const WordPage = () => {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const [orientation, setOrientation] = useState('portrait');
    const [number, setNumber] = useState("");
    const [numberArray, setNumberArray] = useState([]);
    const [sort, setSort] = useState("");

    const duplicatedInputRef = useRef(null);
    const userReducer = useSelector(state => state.userReducer);
    const { Option } = Select;

    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
        return (<div>Needs access permission {userReducer.user.role}</div>)
    }

    registerAllModules();

    const table = (sample, title, headerArr) => {
        //console.log('header array is: ', headerArr);
        // console.log('title is: ', title);
        // console.log('data is: ', sample);

        return `
            <h3>${title}</h3>
            <table width='100%' border='1' cellpadding="0" cellspacing="0" bordercolor="#000000">
            <tr>
                ${headerArr.map(i =>`<th bgColor="#C5EDFD" align="center">${i}</th>`)}
            </tr>
            ${sample.map((row) => (`<tr>${row.map(v => `<td align="center">${v}</td>`)}</tr>`))}
            </table>`.replaceAll(',', '').replaceAll('false', '');
    }

    const sortByColumn = () => {
        const columnValueArr = _.uniq(data.map(x => x[sort])).slice(1);
        const arr = columnValueArr.sort( (a, b) => {
            //console.log(a,  '1=', areas.indexOf(a), ' ', b, ' 2= ', areas.indexOf(b), ' ', areas.indexOf(a) < areas.indexOf(b));
            if (areas.indexOf(a) < areas.indexOf(b))
                return -1
            else
                return 1
        });

        //console.log('sorted ', arr);
        let tables = '';
        const headerArr = data[0];
        headerArr.splice(sort, 1);
        //console.log('headerArr: ', headerArr);

        for (let i=0; i<columnValueArr.length; i++) {
            //remove the column that we want to sort by
            const data2 = data.filter( row => row[sort] == arr[i]);
            const data3 = data2.map(row => {row.splice(sort, 1); return row});

            // console.log('area = ', arr[i], ' ', i, arr);
            // console.log('data: ', data3);

            tables = tables + '<br />' + table(data3, arr[i], headerArr);
        }

        saveAsWord(tables);
    }

    const saveAsWord = async (c) => {
        const content = `
        <html>
            <header>
                <meta charset="UTF-8">
            </header>
            <body>
                <center>
                    <h1>${title}</h1>
                    ${c}
                </center>
            </body>
        </html>`

        const converted = await htmlDocx.asBlob(content, { orientation });
        saveAs(converted, "test.docx");
    }

    const hotSettings = {
        data,
        colHeaders: true,
        height: 'auto',
        contextMenu: ['row_above', 'row_below', '---------', 'col_left', 'col_right', '---------', 'remove_row', 'remove_col', '---------', 'undo', 'redo'],
        licenseKey: 'non-commercial-and-evaluation'
    };

    return (
        <>
            <HeaderComponent />
            <br /><br />
            <Row justify="start">
                <Space>
                    <Upload
                        accept=".xlsx"
                        showUploadList={false}
                        beforeUpload={file => {
                            const reader = new FileReader();

                            reader.onload = async e => {

                                // console.log(e.target.result);
                                let workbook = new excel.Workbook();
                                workbook = await workbook.xlsx.load(e.target.result);
                                let worksheet = workbook.worksheets[0];
                                let arr = [];

                                worksheet.eachRow(async (row, number) => {
                                    //row.values.map( value => console.log(value));
                                    arr.push(row.values);
                                })
                                setData(arr);
                            };
                            reader.readAsArrayBuffer(file);

                            // Prevent upload
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>

                    <Button onClick={() => {
                        const newData  = [];
                        data.forEach(row => {
                            let combinedColumn = "";
                            let newRow = [];
                            console.log('row length = ', row.length);
                            for (let i=0; i<row.length; i++) {
                                if ( numberArray.includes(i.toString())) {
                                    combinedColumn = combinedColumn + " " + (row[i] === undefined ? '' : row[i]);
                                    //console.log('yes found some: ', combinedColumn, ' ', row[i], ' index ', i, 'array ', numberArray);
                                }
                                else {
                                    newRow.push(row[i])
                                }
                            }
                            newRow.push(combinedColumn);

                            newData.push(newRow);
                        });

                        setData(newData);
                    }
                    }>Combine</Button>

                    <Button onClick={() => {
                        const arr = duplicatedInputRef.current.value.split(',');
                        //console.log(data);
                        const duplicationIndexes = [];
                        for (let i=0; i<arr.length; i++) {
                            const tempArr = []
                            data.forEach( (row, index) => {
                                if (tempArr.indexOf(row[arr[i]]) == -1) {
                                    tempArr.push(row[arr[i]])
                                }
                                else {
                                    if (duplicationIndexes.indexOf(index) == -1)
                                        duplicationIndexes.push(index);
                                }
                            })
                        }

                        //console.log('duplicationIndexes: ', duplicationIndexes)
                        for (let i=duplicationIndexes.length-1; i > -1; i--) {           
                            data.splice(duplicationIndexes[i], 1);
                        }

                        setData([...data]);
                    }}>
                        Remove Duplicates
                    </Button>

                    <Button danger onClick={async () => {
                        saveAsWord(table(data, title, data[0]));
                    }}>To Word (Not Sort)</Button>
                    <Button danger onClick={sortByColumn}>To Word (Sorted)</Button>

                    <Select
                        placeholder="Orientation"
                        optionFilterProp="children"
                        allowClear
                        onChange={setOrientation}
                    >
                        <Option value="portrait">Portrait</Option>
                        <Option value="landscape">Landscape</Option>
                    </Select>
                </Space>
            </Row>
            <Row>
                <Col>
                    <br />
                    <input value={number} onChange={e => {setNumber(e.target.value); setNumberArray(e.target.value.split(','))}} type="text" placeholder="Separate by the column number" style={{ width: '50vw' }} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <br />
                    <input value={sort} onChange={e => setSort(e.target.value)} type="text" placeholder="Sort by the column number" style={{ width: '50vw' }} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <br />
                    <input ref={duplicatedInputRef} type="text" placeholder="Remove duplicates by the column numbers" style={{ width: '50vw' }} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <br /><input value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder="Enter the document title" style={{ width: '50vw' }} />
                </Col>
            </Row>
            <br />

            {/* <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Raw Data" key="1">
                    Content of Tab 1
                    <HotTable  colHeaders={true} rowHeaders={true} width="600" height="300" licenseKey="non-commercial-and-evaluation"/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Data 2" key="2">
                    Content of Tab 2
                </Tabs.TabPane>
            </Tabs> */}


            <HotTable settings={hotSettings} />


        </>
    )

}

export default WordPage;