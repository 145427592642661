import { Table, Button, Space, Modal, Form, Select, Input, DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import service from '../config/service';
import fileDownload from 'js-file-download';
import convertor from 'json-2-csv';
import FormEmployee from '../pages/employees/update/form';
import { useSelector } from 'react-redux';

const { Option } = Select;

const AdminACLSComponent = ({ values, submitted, setSubmitted, secret, section }) => {
    //alert('re-rendered');
    const userReducer = useSelector(state => state.userReducer);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEmployeeModalVisible, setIsEmployeeModalVisible] = useState(false);
    const [currentDeleteUser, setCurrentDeleteUser] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [reason, setReason] = useState('');
    const [form] = Form.useForm();

    let updateForm = "updateForm";

    useEffect(() => {
        if (currentDeleteUser && currentDeleteUser.status) {
            form.setFieldsValue({ reason: currentDeleteUser.status?.reason, comments: currentDeleteUser.status?.comments, date: moment(currentDeleteUser.status?.date) });
        }
    }, [currentDeleteUser]);

    const { data, isLoading, isError, isFetching } = useQuery(['data'], async () => {
        const res = await service.getRestClient().post('/employees/get', { ...values, retired: 'not_retired', occupation: 'doctor' });
        setSubmitted(false);
        return res.data.data;
    }, { enabled: submitted, refetchOnWindowFocus: false })

    // used incase of resignation
    const deleteMutation = useMutation(async (value) => {
        await service.getRestClient().post('/employees/deleteEmployee', value)
    }, {
        onSuccess: () => queryClient.refetchQueries('data'),
        onError: (e) => alert('failed to delete entry: ' + e.message)
    })


    const queryClient = useQueryClient();

    const onDownload = async () => {
        const saveData = data.map(item => {
            delete item._id;
            delete item.__v;

            return item;
        })
        const csv = await convertor.json2csvAsync(saveData);
        fileDownload(csv, `${values.occupation || 'data'}.csv`);
    }


    if (isLoading || isFetching)
        return (<div>Loading data ...</div>)

    if (isError)
        return (<div>Error Loading data. Please try again</div>)

    if (!data) {
        return (<div>No data</div>)
    }

    //moment(record.date).isBefore(moment().subtract(2, 'months'))) 
    const backgroundColorRender = (text, record, index) => {
         if ((!Object.keys(record).includes('status') || Object.keys(record.status).length < 1))  {   // emp exist
            if (record.hasACLS === 'no') {
                return {
                    props: {
                        style: {
                            background: '#8d8f94',
                            color: 'white',
                        },
                    },
                    children: <div>{index}</div>,
                }
            }
            else if (record.hasACLS === 'yes') {
                if (moment(record.aclsExpDate).isBefore(moment())) {
                    return {
                        props: {
                            style: {
                                background: 'orange',
                                color: 'white',
                            },
                        },
                        children: <div>{index}</div>,
                    }
                }
                return {
                    props: {
                        style: {
                            background: 'green',
                            color: 'white',
                        },
                    },
                    children: <div>{index}</div>,
                }
            }
            else {
                return {
                    props: {
                        style: {
                            background: '#d7d7d9',
                            color: 'white',
                        },
                    },
                    children: <div>{index}</div>,
                }
            }

        }
        else {
            return <div>{index}</div>;
        }
    }

    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            render: (text, record, index) => backgroundColorRender(index, record, index+1),
        },
        {
            title: 'File No.',
            dataIndex: 'fileNo',
            key: 'fileNo',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Name',
            dataIndex: 'nameEn',
            key: 'nameEn',
            render: (text, record) => {
                return backgroundColorRender(text, record, text);
            }
        },
        {
            title: 'JobTitle',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Health Center',
            dataIndex: 'healthCenter',
            key: 'healthCenter',
            render: (text, record) => backgroundColorRender(text, record, text),
        },        
        {
            title: 'has ACLS',
            dataIndex: 'hasACLS',
            key: 'hasACLS',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Issuance',
            dataIndex: 'aclsIssuance',
            key: 'aclsIssuance',
            render: (text, record) => backgroundColorRender(text, record, text),
        },
        {
            title: 'Expiry Date',
            dataIndex: 'aclsExpDate',
            key: 'aclsExpDate',
            render: (text, record) => backgroundColorRender(text, record, moment(text).format('DD/MM/YYYY')),
        },
        {
            title: 'View',
            key: 'action',
            align: 'center',
            render: (text, record) => {
                let output = "";
                if (record.hasACLS === 'yes')
                    output = <a href={process.env.REACT_APP_BASEURL + '/employees/aclsImage/' + record._id} target="_blank" rel="noopener noreferrer">View</a>
                return backgroundColorRender(text, record, output);
            }
        },        
        {
            title: 'Last Update',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => backgroundColorRender(text, record, moment(text).format('DD/MM/YYYY'))
        }
    ];

    const footer = () => {
        if (userReducer.user.role != 'user') {
            return (
                <center>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onDownload()} size="middle">Export CSV</Button>&nbsp;
                </center>
            )
        }
    }

    // sort for deleted & not deleted
    let tableData1 = data.sort( (emp1, emp2) => {
        const emp1Exist = (!Object.keys(emp1).includes('status') || Object.keys(emp1.status).length < 1)
        const emp2Exist = (!Object.keys(emp2).includes('status') || Object.keys(emp2.status).length < 1)
        if (emp1Exist && emp2Exist)
            return 0;
        else if (emp1Exist && !emp2Exist) 
            return -1;
        else if (!emp1Exist && emp2Exist)
            return 1;
        else if (!emp1Exist && !emp2Exist)
            return 0;
    });

    // sort for HOD of center
    let tableData = tableData1.sort( (emp1, emp2) => {
        const emp1Exist = (!Object.keys(emp1).includes('status') || Object.keys(emp1.status).length < 1)
        const emp2Exist = (!Object.keys(emp2).includes('status') || Object.keys(emp2.status).length < 1)

        if (emp1Exist && emp2Exist) {
            if (emp1.hod == 'yes' && emp2.hod == 'no') {
                return -1;    
            }
            else if (emp1.hod == 'yes' && emp2.hod == 'yes') {
                return 0;
            }
            else if (emp1.hod == 'no' && emp2.hod == 'yes') {
                return 1;
            } 
        }

    })

    return (<div>
        <Table columns={columns} dataSource={tableData} pagination={{ pageSize: 200 }} size="middle" footer={footer} />
    </div>);
}

export default AdminACLSComponent;