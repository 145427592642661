import {CheckSquareOutlined} from '@ant-design/icons';

const ThankyouComponent = () => {
    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 40}}><CheckSquareOutlined style={{fontSize: 100, color: 'green'}}  /></div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20}}>Thank you for updating your information</div>
        </div>
    )
}

export default ThankyouComponent;