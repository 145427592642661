import React from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../actions/userActions';
import { Link, useParams } from 'react-router-dom';


// https://medium.com/better-programming/build-a-react-redux-app-with-javascript-web-tokens-9f2b73768e18

const onFinishFailed = (err) => {

}

const Login = () => {
    const dispatch = useDispatch()
    const userReducer = useSelector(state => state.userReducer)
    const {success} = useParams();

    if (userReducer.loggedIn) {
        if (userReducer.user.role === 'admin' || userReducer.user.role === 'stats' || userReducer.user.role === 'cme') {
            window.location.href = '/admin'
        }
        else {
            window.location.href = '/staff'
        }
    }

    const showSuccessOrNot = () => {
        if (success) {
            return (
            <div>
                <Alert message="Password set successfully, please login now" type="success" />
                <br />
            </div>);
        }
        else {
            return null;
        }
    }
    return (<div className="col-md-4 offset-md-4">
        
        {showSuccessOrNot()}

        <h6>Please log with your username and password:</h6>
        <Form layout="vertical"  width="40%" name="login" initialValues={{ remember: true }} onFinish={(values) => dispatch(fetchUser(values))} onFinishFailed={onFinishFailed}>
            <Form.Item label="email" name="email" rules={[ {required: true, message: "please enter your email"}]}>
                <Input />
            </Form.Item>

            <Form.Item label="password" name="password" rules={[ {required: true, message: "please enter your password"}]}>
                <Input type="password" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={userReducer.loading} disabled={userReducer.loggedIn}>
                    Login
                </Button>
            </Form.Item>
            <Form.Item>
                <Link to="/forget">Forget Password ?</Link>
            </Form.Item>
        </Form>
        {
            userReducer.logginErrorMessage ? <Alert message={userReducer.logginErrorMessage} type="error" /> : ''
        }
        
    </div>);
}

export default Login;