import { useParams, useHistory } from "react-router-dom";
import service from "../../../config/service";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Spin, Alert } from 'antd';
import SurveyComponent from "../../../components/survey";
import moment from "moment";
import { useSelector } from 'react-redux';

const FormEmployee = ({secret, fileNo, civilId, id, onAdd}) => {
    const {section} = useParams();
    //const {fileNo, civilId, id} = useParams();
    const userReducer = useSelector(state => state.userReducer);
    const history = useHistory();

    const fetchData = async () => {
        const res = await service.getRestClient().get(`/employees/json/getEmployee/${fileNo}/${civilId}/${id}`);
        return {data: res.data.data, json: res.data.json};
    }

    const addEntry = (values) => {
        console.log(values);
        return service.getRestClient().post('/employees/data', values)
    }    

    const mutation = useMutation(addEntry, {
        onSuccess: () => {
            queryClient.invalidateQueries(fileNo);
            //alert('Information updated successfully');
            if (onAdd) {
                onAdd();
            }
            else {
                history.push('/thanks');
            }  
        },

        onError: () => alert('Error, please try again')
    });

    const queryClient = useQueryClient();

    const onComplete = (values) => {
        mutation.mutate(values.data);
    }

    
    const {data, isError, isLoading}  =  useQuery([fileNo], () => fetchData(), {refetchOnWindowFocus: false, cacheTime: 0, initialData: undefined});

    if (isLoading) {
        return (
            <Spin spinning={true} size="default">
                <div>Please wait while loading...</div>
            </Spin>
        )
    }

    if (isError)
        return <div><Alert message="Error loading the page, please refresh the page or try again later." type="error"></Alert></div>
        

    if (!data) {
        return <div>No data</div>
    }

    //queryClient.removeQueries(fileNo);

    data.json.pages[0].elements.forEach( i => {
        if (data.data) {

            i.defaultValue = data.data[i.name];
            if (secret) 
                i.isRequired = false

            // if (data.data.transfer?.unconfirmed) {
            //     if (i.name === 'center_id') 
            //         i.defaultValue = data.data.transfer.to;
            // }
            if (userReducer.user.role === 'user' && i.name === 'center_id') {
                i.readOnly = true;
                i.defaultValue = userReducer.user._id;
                
            } 

            if (userReducer.user.role === 'user' && i.name === 'healthArea') {
                i.readOnly = true;
                i.defaultValue = userReducer.user.healthArea;
            } 

            if (userReducer.user.role === 'user' && i.name === 'occupation') {
                i.readOnly = true;
                i.defaultValue = section;
            }
        }
    })


    return (
        <div>
            <Spin spinning={mutation.isLoading}>
                {data.data.updatedAt ? <div>Last update: {moment(data.data.updatedAt).format('DD/MM/yyyy')}</div> : ''}
                <SurveyComponent json={data.json} showCompletedPage={true} onComplete={onComplete} />
            </Spin>
        </div>
    );
}

export default FormEmployee;