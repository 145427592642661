import React from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { forgetPassword } from '../actions/userActions';

const Forget = () => {
    const userReducer = useSelector(state => state.userReducer)
    const dispatch = useDispatch();

    const onFinish = (values) => {
        //alert(JSON.stringify(values))
        if (userReducer.loggedIn) {
            alert("You're already logged in");
        }
        else {
            dispatch(forgetPassword(values));
        }
    }

    return (<div className="col-md-4 offset-md-4">
        <h6>Please enter your registered email:</h6>
        <Form layout="vertical"  width="40%" name="login" initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item label="email" name="email" rules={[ {required: true, message: "please enter your email"}]}>
                <Input />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={userReducer.loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
        {
            userReducer.forgetPasswordMessage ? <Alert message={userReducer.forgetPasswordMessage} type="info" /> : ''
        }
        
    </div>);
}

export default Forget;