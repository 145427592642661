import SurveyComponent from '../../components/survey'
import service from '../../config/service';
import { Spin, Alert, Space, Button, Table, Tag } from 'antd';
import axios from 'axios';
import { useQuery, useMutation, useQueryClient }  from 'react-query';
import { useParams, Redirect }  from 'react-router-dom';
import Moment from 'moment';
import {useSelector} from 'react-redux';
import HeaderComponent from '../../components/header';
import moment from 'moment';


//nice article ?
// https://medium.com/@cant89/data-fetching-in-redux-is-painful-get-rid-of-it-now-2b71305268e1

const fetchData = async (section, userId) => {
    const res = await service.getRestClient().get(`/staff/json/${section}/${userId}`);
    //const req2 = service.getRestClient().get(`/cdphc/healthCenterEmployees/${userId}/${section}`)
    //const [res, employee] = await axios.all([req1, req2]) 
    return {data: res.data.data, json: res.data.json};
}

const addEntry = (values) => {
    return service.getRestClient().post('/staff/data', values)
}

const deleteEntry = (values) => {
    return service.getRestClient().delete('/staff/data', {data: values})
}

const DynamicPage = () => {
    const {section, userId} = useParams();
    const userReducer = useSelector(state => state.userReducer);

    const{data, isError, isLoading, isFetching}  =  useQuery(['data', section, userId], () => fetchData(section, userId), {refetchOnWindowFocus: false});
    
    const mutation = useMutation(addEntry, {
        onSuccess: () => queryClient.invalidateQueries('data'),
        onError: () => console.log('failed adding')
    });

    const deleteMutation = useMutation(deleteEntry, {
        onSuccess: () => queryClient.invalidateQueries('data'),
        onError: () => console.log('failed to delete entry')
    })
    const queryClient = useQueryClient();

    const onComplete = (data) => {
        const values = {...data.data, name: section, center_id: userId}
        mutation.mutate(values);    
    }

    if (!userReducer.loggedIn)
        return <Redirect to="/login" />

    if (isLoading) {
        return (
            <Spin spinning={true} size="default">
                <div>Please wait while loading...</div>
            </Spin>
        )
    }

    if (isError)
        return <div><Alert message="Error loading the page, please refresh the page or try again later." type="error"></Alert></div>
    

    //return (<div>{JSON.stringify(data)}</div>);

    // main start here
    const c = [];
    const c2 = [];

    data.json.pages[0].elements.forEach(i => {
        if (data.data.length > 0)
            i.defaultValue = data.data[0][i.name];
    })

    const columns = [
        {
            title: 'date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: (text, record) => (
                <div>
                    {Moment(text).format('DD/MM/yyyy')}
                </div>
                )

                
        },
        {
        title: '',
        key: 'action',
        align: 'center',
        render: (text, record) => {
            if (moment().isAfter(moment(record.createdAt).add(2, 'days'))) {
                return;
            }

            return  (
                <Space size="middle">
                <Button type="link" onClick={() => deleteMutation.mutate({id: text._id}) }>
                    delete
                </Button>
                </Space>
            )
        }
    }
 ]

    return (
        <div>
            <HeaderComponent />
            <br />
            <Spin spinning={mutation.isLoading || deleteMutation.isLoading}>
                <SurveyComponent json={data.json} showCompletedPage={true} onComplete={onComplete} />
                <br />
                {isFetching && <Alert message="updating ..." type="info" />}
                {deleteMutation.isError && <Alert message="failed to delete..." type="error" closable />}
                <br />
                <Table columns={columns} dataSource={data.data} size="small" />
            </Spin>
        </div>
    );

}

export default DynamicPage;