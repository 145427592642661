import React, { Component } from 'react';
import { Button } from 'antd';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons';
import service from '../config/service';

export default class DownloadFile extends Component {
    constructor() {
        super();
        this.fileService = {}//new FileService();
        this.state={downloading:false};
    }

     extractFileName = (contentDispositionValue) => {
         //console.log("value  ", contentDispositionValue);
         var filename = "";
         if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
             var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
             var matches = filenameRegex.exec(contentDispositionValue);
             if (matches != null && matches[1]) {
                 filename = matches[1].replace(/['"]/g, '');
             }
         }
         return filename;
     }

    downloadFile = () => {
        this.setState({ downloading: true });
        let self = this;
        let link = "";
        if (this.props.department) {
            link = `/survey/downloadPDF/${this.props.department}`
        }
        else {
            link = `/staff_survey/downloadPDF`
        }

        console.log("link = ", link);
        service.getRestClient().get(link, {responseType: 'blob'}).then((response) => {
            //console.log("Response headers", response);
            this.setState({ downloading: false});
            //extract file name from Content-Disposition header
            var filename=this.extractFileName(response.headers['content-disposition']);
            //console.log("File name",filename);
            //invoke 'Save As' dialog
            saveAs(response.data, filename);
            /*
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();*/
          
        }).catch(function (error) {
            console.log(error);
            self.setState({ downloading: false });
            if (error.response) {
                console.log('Error', error.response.status);
            } else {
                console.log('Error', error.message);
            }
        });
    };

    render() {
        //console.log("state",this.state);
        return (
            <div>
                <Button  type="primary" icon={<DownloadOutlined />} size="large" onClick={this.downloadFile} loading={this.state.downloading}>
                    Download QR
                </Button>
            </div>
        )
    };
}
