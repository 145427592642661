import React from 'react';
import { Row, Col, Statistic, Space, Collapse, Input, Button} from 'antd';
import DownloadFile from '../../components/downloadFile';
import service from '../../config/service';
import { useQuery, useQueryClient } from 'react-query';

const SurveyPage = ({date, centerId, healthArea, submitted, setSubmitted}) => {
    //
    const { Panel } = Collapse;    

    const { data, isLoading, isError } = useQuery(['survey_stats'], async () => {
        const res = await service.getRestClient().post('/survey/stats', {date, centerId, healthArea})
        setSubmitted(false);
        return res.data;
    }, { enabled: submitted, refetchOnWindowFocus: false })   

    const queryClient = useQueryClient();

    if (isLoading)
        return (<div>Loading data ...</div>)

    if (isError)
        return (<div>Error Loading data. Please try again</div>)

    if (!data || data?.length < 1) 
        return (<div>No data</div>)
      
    console.log(data);

    return (
        <div>
            <Collapse>
                {data.map(item => {
                return (
                    <Panel header={item.header}>
                        <Row>
                            <DownloadFile department={item.header} />
                        </Row>
                        <br />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {item.result.map(item2 => {
                            return (
                                <Col span={8}>
                                    <Space size="large" direction="vertical">
                                        <Statistic title={item2.title} value={item2.value} precision={2} /><br />
                                    </Space>
                                </Col>
                            )
                            })}
                        </Row>
                    </Panel>
                    )
                })}
            </Collapse>
        </div>

    );  
}

export default SurveyPage;
