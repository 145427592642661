import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import AdminACLSComponent from '../../components/admin-acls';
import service from '../../config/service';
import { Select, Button, Input, Form, Spin } from 'antd';
import HeaderComponent from '../../components/header';

const { Option } = Select;

const AdminACLSPage = () => {
    const userReducer = useSelector(state => state.userReducer);

    const [healthCenters, setHealthCenters] = useState([]);
    const [values, setValues] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [certificates, setCertificates] = useState([]);

    useEffect( () => {
        service.getRestClient().get('/employees/certificates/doctor').then(res => setCertificates(res.data.map(certificate => ({label: certificate, value: certificate}))))
    }, [])

    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
        return (<div>Needs access permission</div>)
    }

    const onSubmit = (v) => {
        //alert(JSON.stringify(v))

        setValues(v);
        setSubmitted(true);
    }

    const onHealthAreaChange = async (value) => {
        try {
            const res = await service.getRestClient().get(`/cdphc/healthCenters/by/area/${value}/date`);
            const options = res.data.map( center => ({label: center.name, value: center._id}) )
            setHealthCenters(options);
        }
        catch(e) {
            console.log(e);
            alert('Error occured while fetching health centers: ' + e.message);
        }
        finally {
            return values;
        }
    }

    return (
        <div>
            <HeaderComponent />
            <br />
            <Form layout="inline" initialValues={{'occupation': 'doctor'}} onFinish={onSubmit}>
                <Form.Item name="healthArea">
                    <Select
                        style={{ width: 200 }}
                        showSearch
                        placeholder="Select health area"
                        optionFilterProp="children"
                        allowClear
                        onChange={onHealthAreaChange}
                    >
                        <Option value="Capital Health Area">Capital Health Area</Option>
                        <Option value="Hawalli Health Area">Hawalli Health Area</Option>
                        <Option value="Farwaniya Health Area">Farwaniya Health Area</Option>
                        <Option value="Mubarak AlKabeer Health Area">Mubarak Al Kabeer Health Area</Option>
                        <Option value="Ahmadi Health Area">Ahmadi Health Area</Option>
                        <Option value="Jahra Health Area">Jahra Health Area</Option>
                        <Option value="All">All</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="center_id">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select Health Center"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                    >
                    {
                        healthCenters.map(item => (
                            <Option value={item.value} key={item.value}>
                                {item.label}
                            </Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item name="hod">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Head of center"
                        optionFilterProp="children"
                        allowClear
                    >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="fileNo">
                    <Input placeholder="fileNo" allowClear style={{ width: 200}}  />
                </Form.Item>                
                <Form.Item name="certificates">
                    <Select
                        mode="multiple"
                        style={{ width: 200 }}
                        placeholder="certificates"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                    >
                    {
                        certificates.map(item => (
                            <Option value={item.value} key={item.value}>
                                {item.label}
                            </Option>
                        ))
                    }
                    </Select>
                </Form.Item>                
                <Form.Item name="hasACLS">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Has ACLS"
                        optionFilterProp="children"
                        allowClear
                    >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                        <Option value="valid">Valid</Option>
                        <Option value="expired">Expired</Option>
                        <Option value="incomplete">Incomplete</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="expireIn">
                    <Input placeholder="Expire In (months)" allowClear style={{ width: 200}} />
                </Form.Item>
                <Button htmlType="submit" type="primary">Submit</Button>
            </Form>   
            <br /><br />
            <Spin spinning={submitted}><AdminACLSComponent values={values} submitted={submitted} setSubmitted={setSubmitted} secret /></Spin>
        </div>
    )

}

export default AdminACLSPage;