import {useQuery, useMutation, useQueryClient } from 'react-query';
import service from '../../config/service';
import moment from 'moment';
import {Alert, Button} from 'antd';

const EmployeesReview = ({submitted, setSubmitted, center_id, occupation, completedEntries}) => {
    const { data, isLoading, isError, isFetching } = useQuery(['reviewed'], async () => {
        const res = await service.getRestClient().get('/employees/getDate?center_id=' + center_id);
        setSubmitted(false);
        return res.data.reviewed;
    }, {enabled: submitted, refetchOnWindowFocus: false})

    const mutation = useMutation(async () => await service.getRestClient().post('/employees/updateDate', {center_id, occupation}), {
        onSuccess: () => queryClient.refetchQueries('reviewed'),
        onError: () => console.log('failed adding')
    });

    const queryClient = useQueryClient();

    let review = {};
    let lastUpdated = 'None';
    if (data) {
        review = data.find(item => item.occupation === occupation) || {}
        lastUpdated = (review.date) ? moment(review.date).format('DD/MM/YYYY HH:mm:ss') : 'None';
    }

    const onClick = () => {
        if (!completedEntries) return alert("Please kindly review and update each employee details by clicking on each name");

//        if (window.confirm('Did you review and updated each emplyee details by clicking on each name ?')) {
            alert('Thank you');
            mutation.mutate();
  //      }
     
    }


    return (
    <div>
        <Alert type="info" message={'Last update: ' + lastUpdated} /><br />
        <Button type="primary" danger style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}} onClick={onClick} isLoading={mutation.isLoading}>Send</Button>
    </div>
    );

}

export default EmployeesReview;