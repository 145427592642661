import service from "../config/service";
import { useEffect, useState } from "react";
import { Select, Button, Input, Form, Spin } from 'antd';


const HealthCentersDropDownComponent = ({ setHealthArea, setCenterId, children, onFinish}) => {
    const [healthCenters, setHealthCenters] = useState([]);
    const [values, setValues] = useState(null);
    const { Option } = Select;


    const onHealthAreaChange = async (value) => {
        try {
            const res = await service.getRestClient().get(`/cdphc/healthCenters/by/area/${value}/date`);
            const options = res.data.map(center => ({ label: center.name, value: center._id }))
            setHealthArea(value);
            setHealthCenters(options);
        }
        catch (e) {
            console.log(e);
            alert('Error occured while fetching health centers: ' + e.message);
        }
        finally {
            return values;
        }
    }

    return (
        <Form layout="inline" onFinish={onFinish}>
            <Form.Item name="healthArea">
                <Select
                    style={{ width: 200 }}
                    showSearch
                    placeholder="Select health area"
                    optionFilterProp="children"
                    allowClear
                    onChange={onHealthAreaChange}
                >
                    <Option value="Capital Health Area">Capital Health Area</Option>
                    <Option value="Hawalli Health Area">Hawalli Health Area</Option>
                    <Option value="Farwaniya Health Area">Farwaniya Health Area</Option>
                    <Option value="Mubarak AlKabeer Health Area">Mubarak Al Kabeer Health Area</Option>
                    <Option value="Ahmadi Health Area">Ahmadi Health Area</Option>
                    <Option value="Jahra Health Area">Jahra Health Area</Option>
                    <Option value="All">All</Option>
                </Select>
            </Form.Item>
            <Form.Item name="center_id">
                <Select
                    style={{ width: 200 }}
                    placeholder="Select Health Center"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    onChange={(value) => setCenterId(value)}
                >
                    {
                        healthCenters.map(item => (
                            <Option value={item.value} key={item.value}>
                                {item.label}
                            </Option>
                        ))
                    }
                </Select>
            </Form.Item>
            {children}
        </Form>
    );
}

export default HealthCentersDropDownComponent;