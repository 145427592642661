import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import HeaderComponent from '../../../components/header';
import service from '../../../config/service';
import { useParams } from 'react-router-dom';
import {Card, Table, Button, Modal, Form, Input, Upload, message, Spin} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';


const ReportDetailPage = () => {
    const {reportId} = useParams();
    const {data, isLoading, isError } = useQuery('reportDetail', async () => await service.getRestClient().post('/reports/reportDetail', {_id: reportId}), {refetchOnWindowFocus: false});
    const addMutation = useMutation(async  file => {
        return service.getRestClient().post('/reports/addFile', file);
    }, {
        onSuccess: () => {
            setVisible(false);
            queryClient.invalidateQueries('reportDetail');
            console.log('success');
        },
        onError: (error) => {
            setVisible(false);
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }
    });

    const deleteMutation = useMutation(async info => {
        return service.getRestClient().post('/reports/deleteFile', {_id: info._id, file: info.file});
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('reportDetail');
            console.log('successfully deleted');
        },
        onError: (error) => {
            console.log('error');
            alert('error deleting file');
        }
    });


    const queryClient = useQueryClient();

    // const userReducer = useSelector(state => state.userReducer);
    const [visible, setVisible] = useState(false);
    const [uploadVisible, setUploadVisible] = useState(false);
    let rows = [];

    
    // if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
    //     return (<div>Needs access permission {userReducer.user.role}</div>)
    // }


    if (isLoading)
        return <div><HeaderComponent /> Loading</div>

    if (isError)
        return <div><HeaderComponent /> Error Loading Page</div>

    if (!data) {
        return <div><HeaderComponent /> Not found</div>
    }

    console.log(data);

    const columns = [
        {
            title: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (text, record) => <a href={process.env.REACT_APP_BASEURL + '/reports/' + text} target="_blank">{text}</a>
        },
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            render: (text, record) => moment(text).format('DD/MM/YYYY h:mm:ss')
        },
        {
            title: '',
            render: (text, record) => <a onClick={() => deleteMutation.mutate({_id: data.data._id, file: text})}>Delete</a>
        }
    ]; 

    return (
        <div>
            <HeaderComponent /><br />
            <div>
                <Button type="primary" onClick={() => setUploadVisible(true)}>Add Report</Button>
            </div><br />
            <Spin spinning={addMutation.isLoading||deleteMutation.isLoading}><Table dataSource={data.data.files} columns={columns} pagination={{pageSize: 1000}} /></Spin>

            <Modal
                title="Add a new file"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Form layout="vertical" onFinish={(values) => addMutation.mutate(values) }>
                    <Form.Item label="File Name" name="name" required="true">
                        <Input placeholder="File Name" />
                    </Form.Item>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Modal> 
            <Modal
                title="Upload File"
                visible={uploadVisible}
                onCancel={() => setUploadVisible(false)}
                onOk={() => {
                    setUploadVisible(false);
                    queryClient.invalidateQueries('reportDetail');
                }}
            >
                <Upload 
                    name="file"
                    action={`${process.env.REACT_APP_BASEURL}/reports/uploadFile?id=${data.data._id}`}
                    headers={{
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }}
                    onChange={(info) => {
                        if (info.file.status === 'done') {
                            message.success(`${info.file.name} file uploaded successfully`);
                            queryClient.invalidateQueries('reportDetail');
                        } else if (info.file.status === 'error') {
                            message.error(`${info.file.name} file upload failed.`);
                        }
                    }
                    }


                >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Modal>  
        </div>
    )
}

export default ReportDetailPage;