import { useSelector } from "react-redux";
import { useState } from 'react';
import HeaderComponent from '../../../components/header';
import Card from '../../../components/card';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import service from '../../../config/service';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Spin } from 'antd';
import moment from 'moment';


const ReportsPage = () => {
    const {data, isLoading, isError } = useQuery('reports', async () => await service.getRestClient().get('/reports/get'), {refetchOnWindowFocus: false});

    const [reportName, setReportName] = useState('');

    const addMutation = useMutation(async name => {
        return service.getRestClient().post('/reports/add', {name});
    }, {
        onSuccess: () => {
            setReportName('');
            queryClient.invalidateQueries('reports');
            console.log('success');
        },
        onError: (error) => {
            console.log('error');
            alert('error: '+ (error.response.data.error));
        }
    });

    const queryClient = useQueryClient();
    const history = useHistory();


    const userReducer = useSelector(state => state.userReducer);

    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
        return (<div>Needs access permission {userReducer.user.role}</div>)
    }

    if (isLoading)
        return <div><HeaderComponent /> Loading</div>

    if (isError)
        return <div><HeaderComponent /> Error Loading Page</div>


    return (<div className="container" >
            <HeaderComponent />
            <br /><br />
            <div>
                <Form layout="vertical" onFinish={(values) => addMutation.mutate(values.name) }>
                    <Form.Item label="Report Name" name="name" required="true">
                        <Input placeholder="Report Name" />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add
                    </Button>
                </Form>
            </div>
            <br /><br />
            <Spin spinning={addMutation.isLoading}>
                <div className="row">
                    {data.data.map(e => <Card key={e.id} title={e.name} button="edit" content={'last update: ' + moment(e.updatedAt).format('DD/MM/YYYY')} onClick={() => history.push(`/admin/reports/${e._id}`)} />)}
                </div>
            </Spin>

    </div>);
}

export default ReportsPage;