import { useSelector } from "react-redux";
import { Select, DatePicker, Button, Alert } from 'antd';
import { useState } from "react";
import service from '../../config/service';
import Admin from '../../components/admin';
import fileDownload from 'js-file-download';
import HeaderComponent from '../../components/header';
import moment from "moment";


const { Option } = Select;


const AdminPage = () => {
    const userReducer = useSelector(state => state.userReducer);
    const [data, setData] = useState([]);
    const [healthArea, setHealthArea] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [date, setDate] = useState([])

    
    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
        return (<div>Needs access permission {userReducer.user.role}</div>)
    }

    const onChange = async (value) => {
        setHealthArea(value);
    }

    const onSubmit = async () => {
        console.log(date);
        setLoading(true);
        setError(false);

        try {
            const res = await service.getRestClient().get(`/cdphc/healthCenters/by/area/${healthArea}/${date}`);
            setLoading(false);
            setError(false);
            setData(res.data);
        }
        catch(e) {
            setLoading(false);
            setError(true);
        }
    }

    const onSubmitDownload = async (section, centerId) => {
        try {
            const res = await service.getRestClient().post('/staff/statistics', {section, centerId});
            fileDownload(res.data, `${centerId}-${section}.csv`);
        }
        catch (e) {
            console.log(e);
            Alert('failed to download file');
        }
    }

    const onSubmitDownloadAll = async (section) => {
        try {
            const res = await service.getRestClient().post('/staff/all/statistics', {section, healthArea, date})
            fileDownload(res.data, `${healthArea}-${section}-${date}.csv`);
        }
        catch (e) {
            console.log(e);
            Alert('failed to download file');
        }
    }

    const regions = () => {
        if (userReducer.user.role === 'admin') {
            return (
                    <Select
                    style={{ width: 200 }}
                    placeholder="Select health area"
                    optionFilterProp="children"
                    onChange={onChange}
                >
                    <Option value="Capital Health Area">Capital Health Area</Option>
                    <Option value="Hawalli Health Area">Hawalli Health Area</Option>
                    <Option value="Farwaniya Health Area">Farwaniya Health Area</Option>
                    <Option value="Mubarak AlKabeer Health Area">Mubarak Al Kabeer Health Area</Option>
                    <Option value="Ahmadi Health Area">Ahmadi Health Area</Option>
                    <Option value="Jahra Health Area">Jahra Health Area</Option>
                    <Option value="All">All</Option>

                    </Select>
            )
        }
        else {
            return (
                <Select
                style={{ width: 200 }}
                placeholder="Select health area"
                optionFilterProp="children"
                onChange={onChange}
            >
                <Option value={userReducer.user.healthArea}>{userReducer.user.healthArea}</Option>

                </Select>
        )
        }
    }

        return (
            <div>
                <HeaderComponent />

                    {regions()}

                <DatePicker picker="month" onPanelChange={value => setDate(moment(value).startOf('month'))} />&nbsp;<Button onClick={() => onSubmit()}>Submit</Button>
                <br /><br />
                <Admin isLoading={isLoading} isError={isError} data={data} onSubmit={onSubmitDownload} onSubmitAll={onSubmitDownloadAll} date={date} />   
            </div>
        )
}

export default AdminPage;